import React from "react";

const ShippingPolicy = () => {
  return (
    <>
    <section>
        <div class="about-dhanvikas header-space">
          <div class="container custom-container">
            <div class="row">
              <div class="col-12">
                <div class="dhanvikas-heading">
                  <h2 class="fw-bold heading-color">
                  Shipping <span class="text-theme-green">Policy</span>
                  </h2>
                </div>
                <div class="mt-xl-4 mt-3">
                  <p>
                  Dhanvikas Fiscal Services Private Limited does not have any Shipping policy as it is not needed as per business requirements.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
       
        </div>
      </section>
    </>
  );
};

export default ShippingPolicy;
