import React, { useEffect, useState } from "react";
import { NavLink, useLocation, props, Link } from "react-router-dom";
import RedirectionModal from "./RedirectModal";

const Header = () => {
  // const getNavLinkClass = (path) => {
  //   console.log(location.pathname);
  //   return this.props.location.pathname === path ? 'active' : '';
  // }
  const [redirectionUrl, setRedirectionUrl] = useState('');


   function redirectionModalOpen(url){
    setRedirectionUrl(url);
    document.getElementById("redirection-modal").style.display = "block";
  };
 

  return (
    <>
       <header
        className="smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-2 mt-1"
      >
        <div className="container custom-container">
          <div className="header-wrap rounded-20">
            <div className="top-header text-white bg-black pt-1 pb-1">
              <div className="row mt-2 mb-1">
                <div className="col-md-12 text-end">
                 
                </div>
              </div>
            </div>
            <div className="mein-header">
              <div className="row align-items-lg-center justify-content-between">
                <div className="col">
                  <div className="dhanvikas-logo">
                    <a href="/"
                      ><img
                        src="/Dhanvikas-Logo.svg"
                        alt="Dhanvikas"
                        title="Dhanvikas"
                    /></a>
                  </div>
                  <div
                    className="touch-in-dhanvikas d-xl-none d-flex align-items-center"
                  >
                    <a
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                      onClick= {()=>{
                        redirectionModalOpen("https://solfin.co.in/login")
                      }}
                      href="#"
                      >Login</a
                    >
                  </div>
                  <button
                    className="navbar-toggler d-xl-none d-xl-block"
                    type="button"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars" aria-hidden="true"></span>
                  </button>
                </div>
                <div className="col">
                  <nav className="navbar navbar-expand-xl p-0" id="dhanvikas-nav">
                    <div className="container-fluid">
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              href="/"
                              aria-current="page"
                              >Home</a
                            >
                          </li>
                          <li className="nav-item ">
                            <a
                              className="nav-link "
                              onClick= {()=>{
                                redirectionModalOpen("https://solfin.co.in")
                              }}
                              href="#"
                              role="button"
                              data-bs-toggle=""
                              aria-expanded="false"
                              >I Want Solar</a
                            >
                          </li>
                          <li className="nav-item ">
                            <a
                              className="nav-link "
                              onClick= {()=>{
                                redirectionModalOpen("https://solfin.co.in")
                              }}
                              href="#"
                              role="button"
                              data-bs-toggle=""
                              aria-expanded="false"
                              >Finance</a
                            >
                        
                          </li>
                          <li className="nav-item ">
                            <a
                              className="nav-link "
                              onClick= {()=>{
                                redirectionModalOpen("https://solfin.co.in")
                              }}
                              href="#"
                              role="button"
                              data-bs-toggle=""
                              aria-expanded="false"
                              >Partners</a
                            >
                           
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" onClick= {()=>{
                                redirectionModalOpen("https://solfin.co.in/mission")
                              }}
                              href="#"
                              >Mission</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col d-none d-xl-block right-col">
                  <div
                    className="right-header d-flex align-items-center justify-content-end"
                  >
                    <div className="touch-in-dhanvikas ms-xxl-5 ms-3">
                      <a
                        className="btn btn-primary btn-header rounded-5 text-nowrap"
                        onClick= {()=>{
                          redirectionModalOpen("https://solfin.co.in/login")
                        }}
                        href="#"
                        >Login</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RedirectionModal redirectionUrl={redirectionUrl} id={"redirection-modal"}/>
      </header>
    </>
  );
};

export default Header;
