import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Termsandconditions from "../pages/termsandconditions/Termsandconditions";
import PrivacyPolicy from "../pages/privacypolicy/Privacypolicy";
import RefundPolicy from "../pages/refundPolicy/RefundPolicy";
import ShippingPolicy from "../pages/shippingPolicy/ShippingPolicy";

const AllRoutes = (props) => {

  return (
    <>
    <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/Privacypolicy"
          element={
            <>
              <Header />
              <PrivacyPolicy />
              <Footer />
            </>
          }
        />
         <Route
          path="/Shippingpolicy"
          element={
            <>
              <Header />
              <ShippingPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/Refundpolicy"
          element={
            <>
              <Header />
              <RefundPolicy />
              <Footer />
            </>
          }
        />
        <Route
          path="/Termsandconditions"
          element={
            <>
              <Header />
              <Termsandconditions />
              <Footer />
            </>
          }
        />  
        </Routes>
    </>
  );
};

export default AllRoutes;
