import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


const RedirectionModal = ({redirectionUrl, id}) => {
  function redirectionModalClose(){
    document.getElementById(id).style.display = "none";
  };
  return (
    <>
      <div
        className="modal fade show"
        id={id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Redirection Alert!
              </h1>
              <button
                type="button"
                className="btn-close btn green-grey"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={redirectionModalClose}
              ></button>
            </div>
            <div className="modal-body">
              <p className="text-dark">
                You will be redirected to https://solfin.co.in which is our Service provider partner?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info light-btn" 
                data-bs-dismiss="modal"
                onClick={redirectionModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-info green-btn"
                data-bs-dismiss="modal"
                onClick={() => {window.location.href = redirectionUrl}}
              >
                Redirect
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectionModal;
