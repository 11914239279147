import { useFormik } from "formik";
import "./App.css";
import AllRoutes from "./FrontEnd/routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  checkboxes: yup.array().min(1, "Select at least one checkbox"),
});

function App() {
  const formik = useFormik({
    initialValues: {
      checkboxes: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      // console.log(values.checkboxes);
    },
  });

  
  return (
    <>
      <AllRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
