import React from "react";
import { NavLink, useLocation, props } from "react-router-dom";
const Footer = () => {
  return (
    <>
     <footer>
        <div className="main-footer">
          <div className="container custom-container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-about-dhanvikas">
                  <div className="footer-logo">
                    <img
                      src="Dhanvikas-Logo.svg"
                      alt="Dhanvikas"
                      title="Dhanvikas"
                    />
                  </div>
                  <div className="footer-text mt-3 mt-xl-4">
                    <p>
                      Enabling finance for all sustainable, renewable and
                      climate friendly projects throughout India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-heading mt-4 text-white">
                  <h6 className="fw-bold">Quick links</h6>
                </div>
                <div className="footer-link mt-lg-4 mt-3">
                  <ul className="list-unstyled m-0 p-0">
                    <li>
                      <a className="" href="/Privacypolicy">Privacy Policy</a>
                    </li>
                    <li>
                      <a className="" href="/Termsandconditions"
                        >Terms and Conditions</a
                      >
                    </li>
                    <li>
                      <a className="" href="/Shippingpolicy"
                        >Shipping Policy</a
                      >
                    </li>
                    <li>
                        <a className="" href="/RefundPolicy"
                          >Refund Policy</a
                        >
                      </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-2 col-sm-6 mt-lg-0 mt-4">
                <div className="footer-heading mt-4 ">
                  <h6 className="fw-bold">Contact</h6>
                </div>
                <div className="footer-link mt-lg-4 mt-3">
                  <div className="contact-via ">
                    <p className="d-flex">
                      <span><i className="fa fa-user" aria-hidden="true"></i></span
                      ><span>Dhanvikas Fiscal Services Private Limited,</span>
                    </p>
                    <p className="d-flex">
                      <span
                        ><i
                          className="fa fa-address-card"
                          aria-hidden="true"
                        ></i></span
                      ><span>U65999MH2018PTC305249</span>
                    </p>
                    <p className="d-flex">
                      <span
                        ><i
                          className="fa fa-map-marker"
                          aria-hidden="true"
                        ></i></span
                      ><span>Flat No 06, 3rd Floor, Sonmarg 67 B, Nepean Sea Road, Malabar Hills, Mumbai- 400006</span>
                    </p>
                    <p className="d-flex">
                      <span
                        ><i className="fa fa-envelope" aria-hidden="true"></i></span
                      ><span
                        ><a
                          className="text-decoration-none"
                          href="mailto:support@dhanvikas.com"
                          >support@dhanvikas.com</a
                        ></span
                      >
                    </p>
                    <p className="d-flex">
                      <span><i className="fa fa-phone" aria-hidden="true"></i></span
                      ><span
                        ><a
                          className="text-decoration-none"
                          href="tel:+919871418470"
                          >+91 9871418470</a
                        ></span
                      >
                    </p>
                  </div>
                </div>
              </div>
           
            </div>
          </div>
        </div>
        <div className="dhanvikas-copyright">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 text-center">
                <span
                  >Copyright © <strong>Dhanvikas</strong> 2023. All Rights
                  Reserved.</span
                >
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
